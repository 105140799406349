import React, { useEffect, useState } from 'react'
import Logo from './Images/Logo.svg'
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useBreakpointValue,
  Stack,
} from '@chakra-ui/react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { Link, useLocation } from 'react-router-dom'


const Navbar = () => {
    const {pathname} = useLocation()
    console.log(pathname)
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isDrawer = useBreakpointValue({ base: true, md: false });
    const [scrollOffset, setOffset] = useState(false);
    useEffect(() => {
      const handleScroll = () => {
        const offset = window.scrollY;
        setOffset(offset > 1000);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  return (
    <header className={ pathname==='/'&& !scrollOffset?'md:text-white fixed px-[5%] py-2 h-fit flex-row flex z-30 justify-between w-[100%] items-center md:text-[1vw]':'md:text-white fixed px-[5%] bg-[#0F182B] py-2 h-fit flex-row flex z-30 justify-between w-[100%]  items-center md:text-[1vw]'}>
        <Link to={'/'}>
          <img src={Logo} alt="logo" loading="lazy"  className=" w-[100%]" />
        </Link>
        {isDrawer ? (
        <>
          <button colorScheme='teal' variant='solid' className='text-white' onClick={onOpen}><GiHamburgerMenu/></button>
          <Drawer colorScheme='blackAlpha' isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay>
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader bgColor='#0F182B'><img src={Logo} alt="phone" loading="lazy"  className="w-auto h-auto " width='160' height='60' /></DrawerHeader>
                <DrawerBody bgColor='#0F182B'>
                <Stack className='flex items-center space-y-12 font-Inknut'>
                <div className={` ${pathname === '/' ? 'flex flex-col items-center item-div-on ' : ' flex flex-col items-center item-div '}`}>
                <Link to={'/'} className={` ${pathname === '/' ? 'item-div-on ' : 'item-div '}`}>Home</Link>
                  <span  className={` ${pathname === '/' ? ' relative top-1 hover-bar bar-cli item-div' : 'relative top-1 hover-bar'}`} ></span>
              </div>
              <div className={` ${pathname === '/Directors' ? 'flex flex-col items-center item-div-on ' : ' flex flex-col items-center item-div '}`}>
                  <Link to={'/Directors'} className={` ${pathname === '/Directors' ? 'item-div-on ' : 'item-div '}`}>Directors</Link>
                <span  className={` ${pathname === '/Directors' ? ' relative top-1 hover-bar bar-cli item-div' : 'relative top-1 hover-bar'}`} ></span>
             </div>
               <div className={` ${pathname === '/Statistics' ? 'flex flex-col items-center item-div-on ' : ' flex flex-col items-center item-div '}`}>
                <Link to={'/Statistics'} className={` ${pathname === '/Statistics' ? 'item-div-on ' : 'item-div '}`}>Statistics</Link>
                <span  className={` ${pathname === '/Statistics' ? ' relative top-1 hover-bar bar-cli item-div' : 'relative top-1 hover-bar'}`} ></span>
              </div>
               <div className={` ${pathname.includes('/Blog') ? 'flex flex-col items-center item-div-on ' : ' flex flex-col items-center item-div '}`}>
                  <Link to={'/Blog'} className={` ${pathname.includes('/Blog')  ? 'item-div-on ' : 'item-div '}`}>Blog</Link>
                <span  className={` ${pathname.includes('/Blog') ? ' relative top-1 hover-bar bar-cli item-div' : 'relative top-1 hover-bar'}`} ></span>
              </div>
                <div className={` ${pathname === '/Career' ? 'flex flex-col items-center item-div-on ' : ' flex flex-col items-center item-div '}`}>
                 <Link to={'/Career'} className={` ${pathname === '/Career' ? 'item-div-on ' : 'item-div '}`}>Career</Link>
                 <span  className={` ${pathname === '/Career' ? ' relative top-1 hover-bar bar-cli item-div' : 'relative top-1 hover-bar'}`} ></span>
              </div>
              <div className={` ${pathname === '/About' ? 'flex flex-col items-center item-div-on ' : ' flex flex-col items-center item-div '}`}>
                <Link to={'/About'} className={` ${pathname === '/About' ? 'item-div-on ' : 'item-div '}`}>About Us</Link>
                  <span  className={` ${pathname === '/About' ? ' relative top-1 hover-bar bar-cli item-div' : 'relative top-1 hover-bar'}`} ></span>
               </div>
            </Stack>
                </DrawerBody>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
        </>
      ) : (
        <div className='flex items-center gap-8 font-Inknut'>
                <div className={` ${pathname === '/' ? 'flex flex-col items-center item-div-on ' : ' flex flex-col items-center item-div '}`}>
                <Link to={'/'} className={` ${pathname === '/' ? 'item-div-on ' : 'item-div '}`}>Home</Link>
                  <span  className={` ${pathname === '/' ? ' relative top-1 hover-bar bar-cli item-div' : 'relative top-1 hover-bar'}`} ></span>
              </div>
              <div className={` ${pathname === '/Directors' ? 'flex flex-col items-center item-div-on ' : ' flex flex-col items-center item-div '}`}>
                  <Link to={'/Directors'} className={` ${pathname === '/Directors' ? 'item-div-on ' : 'item-div '}`}>Directors</Link>
                <span  className={` ${pathname === '/Directors' ? ' relative top-1 hover-bar bar-cli item-div' : 'relative top-1 hover-bar'}`} ></span>
             </div>
               <div className={` ${pathname === '/Statistics' ? 'flex flex-col items-center item-div-on ' : ' flex flex-col items-center item-div '}`}>
                <Link to={'/Statistics'} className={` ${pathname === '/Statistics' ? 'item-div-on ' : 'item-div '}`}>Statistics</Link>
                <span  className={` ${pathname === '/Statistics' ? ' relative top-1 hover-bar bar-cli item-div' : 'relative top-1 hover-bar'}`} ></span>
              </div>
               <div className={` ${pathname.includes('/Blog')  ? 'flex flex-col items-center item-div-on ' : ' flex flex-col items-center item-div '}`}>
                  <Link to={'/Blog'} className={` ${pathname.includes('/Blog')  ? 'item-div-on ' : 'item-div '}`}>Blog</Link>
                <span  className={` ${pathname.includes('/Blog')  ? ' relative top-1 hover-bar bar-cli item-div' : 'relative top-1 hover-bar'}`} ></span>
              </div>
                <div className={` ${pathname === '/Career' ? 'flex flex-col items-center item-div-on ' : ' flex flex-col items-center item-div '}`}>
                 <Link to={'/Career'} className={` ${pathname === '/Career' ? 'item-div-on ' : 'item-div '}`}>Career</Link>
                 <span  className={` ${pathname === '/Career' ? ' relative top-1 hover-bar bar-cli item-div' : 'relative top-1 hover-bar'}`} ></span>
              </div>
              <div className={` ${pathname === '/About' ? 'flex flex-col items-center item-div-on ' : ' flex flex-col items-center item-div '}`}>
                <Link to={'/About'} className={` ${pathname === '/About' ? 'item-div-on ' : 'item-div '}`}>About Us</Link>
                  <span  className={` ${pathname === '/About' ? ' relative top-1 hover-bar bar-cli item-div' : 'relative top-1 hover-bar'}`} ></span>
               </div>
            </div>
      )}
    </header>
    
    

    
  )
}

export default Navbar