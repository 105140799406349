import React from 'react'
import instagram from './Images/ig.png'
import instalogo from './Images/instagramiconspng.parspng.com-2.png'
import ig from './Images/instagramicon.svg'

export default function Frame1() {
  return (
    <section className='flex justify-between sm:flex-row flex-col sm:w-[100%] w-[80%]  sm:mx-[5%] mx-[8%]'>
       <div className='sm:w-[90vw] w-0 sm:visible invisible flex justify-center'>

        <img src={instalogo} loading="lazy" className=''  alt='Phone' />
       </div>

   
      <div className=' flex flex-col justify-between sm:gap-0 gap-6'>
        <p className=' font-Inknut sm:text-[3vw] text-[6vw]  font-light'>Follow Us on</p>
        <div className='flex flex-row items-center '   >
          <img onClick={() => window.open('https://instagram.com/sicu.aura')} alt='ig' className=' hover:cursor-pointer  sm:w-[40vw] w-[80%] ' loading="lazy" src={instagram} />

        </div>
        <p className=' sm:text-[1vw] text-[3vw] text-justify sm:w-[70%]  font-Inknut '>Get the opportunity to experience Next Generation SOS 
        System at first. 200 users will be selected as a Beta Tester on a first come first serve basis. Testing will be done in 8 
        phases. Each phase’s duration will be 7 days and there will be 200 Testers.</p>
        <div className=' w-[100%] sm:w-0 sm:invisible visible flex mx-[5%]'>

<img src={instalogo} loading="lazy"  alt='Phone' />
</div>

        <button onClick={() => window.open('https://www.instagram.com/dream_is_live_/')} 
            className=' bg-white flex gap-[5%] justify-center items-center border rounded-full text-black font-Inknut font-semibold sm:text-[1vw] text-[3vw] sm:w-[20vw] p-[2%]'>
            <img src={ig} className='sm:w-[15%] w-[13%]' alt='linkdin'></img>
            Follow us
        </button>
      </div>
    </section>
  )
}
