import React from "react";
import Hero from "../LandingPage/Hero";
import Join from "../LandingPage/Join";
import Vision from "../LandingPage/Vision";
import Footer from "../../Footer";
import { motion } from "framer-motion";
import Navbar from "../../Navbar";

function LandingPage() {
  return (
    <div className="overflow-hidden ">
      <Navbar/>
      <Hero />
      <Join />
      <Vision />
      <Footer />
    </div>
  );
}

export default LandingPage;
