import React from "react";
import Phone from "./Images/Phone1.svg";
export default function Frame1() {
  return (
    <section className="flex justify-between sm:flex-row flex-col sm:mx-[5%] mx-[8%] ">
      <div className="flex justify-center">
        <img src={Phone} loading="lazy" className="sm:w-[40vw] w-0 sm:visible invisible" alt="Phone" />
      </div>
      <div className="sm:w-[40vw] flex flex-col justify-between sm:gap-0 gap-10">
        <p className=" font-Inknut font-semibold sm:text-[3vw] text-[6vw]  tracking-wide ">
          It's The Time For Testing. Join Us as a <h1>Beta Tester NOW</h1>
        </p>
        <div className="flex justify-center">
        <img src={Phone} loading="lazy" className="sm:w-[0vw] w-[80%] " alt="Phone" />
      </div>
        <p className=" sm:text-[1vw] text-[3vw] tracking-wider text-justify font-Inknut ">
        Get the opportunity to experience Next Generation SOS
        System at first. 200 users will be selected as a Beta Tester
         on a first come first serve basis. Testing will be done in 8
          phases. Each phase’s duration will be 7 days and there 
          will be 200 Testers.
        </p>
        <button className=" bg-white border rounded-full text-black font-Inknut font-semibold sm:text-[1vw] text-[4vw] sm:w-[20vw] p-[4%]  ">
          Coming Soon
        </button>
      </div>
    </section>
  );
}
