import React from "react";
import OurVision from "../../assets/VisionColor.mp4";

const Vision = () => {
  return (
    <div className="w-[100%] h-auto flex sm:flex-row flex-col-reverse flex-wrap justify-around items-center ">
      <section className="flex flex-col gap-4 sm:w-[40%] w-[80%]">
        <p className=" font-Inknut sm:text-[1vw] text-[3vw] text-justify">
          {`Imagine a world where fear takes a backseat, replaced by a comforting sense of security. Where lives flourish unburdened by the anxieties of crime and accidents. Where technology seamlessly integrates with our lives, not just making them easier but actively safeguarding them. This is the vision of Sicu-aura.`}
        </p>
        <p className=" font-Inknut sm:text-[1vw] text-[3vw] text-justify">
          {`Sicu-aura is not just a company, it's a movement. We believe that by working together, we can build a safer and easier world for everyone. A world where communities thrive, families feel protected, and individuals live with a sense of peace and freedom.`}
        </p>
      </section>

      <video
        src={OurVision}
        loading="lazy"
        className="sm:w-[40vw]"
        autoPlay
        loop
        muted
        playsInline
        alt=" Our Vision "
      />
    </div>
  );
};

export default Vision;
