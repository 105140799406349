import React from 'react';
import facebook from './Images/facebookicon.svg'
import instagram from './Images/instagramicon.svg';
import linkedin from './Images/linkedinicon.svg';
import whatsapp from './Images/whatsappicon.svg';
import x from './Images/Twitter.svg'
const Socials = ({ top }) => {
    return (
        <div className={`flex flex-col items-center justify-around gap-6 top-${top} bottom-40 w-7 sm:w-8 left-0 fixed z-10 `}>
            <img loading="lazy" alt="LI"  className=' hover:cursor-pointer w-[80%] sm:w-[100%]' onClick={()=>window.open('https://www.linkedin.com/company/sicu-aura/')}  src={linkedin} />
            <img loading="lazy" alt="FB"  className=' hover:cursor-pointer w-[80%] sm:w-[100%]' onClick={()=>window.open('https://www.facebook.com/profile.php?id=100092369332099&mibextid=LQQJ4d')} src={facebook} /> 
            <img loading="lazy" alt="WA"  src={whatsapp} onClick={()=>window.open('https://wa.me/message/TPL7LZQA3Z3MM1')} className="text-2xl hover:cursor-pointer w-[80%] sm:w-[100%]" />
            <img loading="lazy" alt="X"  src={x} className=' hover:cursor-pointer w-[90%] sm:w-[100%]' onClick={()=>window.open('https://twitter.com/SicuAura')}  />
            <img loading="lazy" alt="IG"  src={instagram} className=' hover:cursor-pointer w-[80%] sm:w-[100%]' onClick={()=>window.open('https://instagram.com/sicu.aura')}  />
        </div>
    )
}

export default Socials;