import React from 'react'
import Phone from './assets/Phone Asset 2-1.mp4'
import Facebook from './Images/facebook.svg'
import fb from './Images/facebookicon.svg';


export default function Frame1() {
  return (
    <section className='flex justify-between sm:flex-row flex-col sm:w-[100%] w-[80%]  sm:mx-[5%] mx-[8%] '>
      <div className='sm:w-[55vw] w-0 sm:visible invisible flex justify-center'>
        <video src={Phone} loading="lazy" autoPlay loop muted playsInline className='' alt='Phone' />
      </div>
      <div className=' flex flex-col justify-between sm:gap-0 gap-10'>

        <p className=' font-Inknut sm:text-[3vw] text-[6vw]  font-light'>Follow Us on</p>
        <img src={Facebook} loading="lazy" className='sm:w-[40vw] w-[80%]  ' alt='Phone' />


        <p className='sm:text-[1vw] text-[3vw] text-justify sm:w-[60%]  font-Inknut '>
          Get the opportunity to experience Next Generation SOS
          System at first. 200 users will be selected as a Beta Tester
          on a first come first serve basis.
        </p>
        <div className=' w-[90%] sm:w-0 sm:invisible visible flex justify-center'>
          <video src={Phone} loading="lazy" autoPlay loop muted playsInline className='' alt='Phone' />
        </div>
        <button onClick={() => window.open('https://www.facebook.com/profile.php?id=100092369332099&mibextid=LQQJ4d')}
          className=' bg-white flex gap-[5%] justify-center items-center border rounded-full text-black font-Inknut font-semibold sm:text-[1vw] text-[3vw] sm:w-[20vw] p-[2%]'>
          <img src={fb} className='sm:w-[15%] w-[13%]' alt='linkdin'></img>
          Follow us
        </button>
      </div>
    </section>


  )
}
