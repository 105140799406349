import React from 'react';
import Socials from '../../Socials';
import worldMap from '../../Images/World Map.png';
import { motion } from 'framer-motion';

const Hero = () => {
  return (
    <div>
      <Socials />
      <main className='flex flex-col  items-center  groupbg overflow-hidden h-[90vh] lg:h-[1000px]'>
        <section className='flex sm:justify-between justify-around  w-[90%] sm:mt-[7%] mt-[10vh]'>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              x: { type: "spring", stiffness: 50 },
                   duration: 1}}
            className='sm:w-[50vw] sm:mt-[4%] mt-[7vh]'>
            <p className='sm:text-[3.5vw] text-[7vw] font-Inknut' style={{ lineHeight: 1.5 }}>
              We're Here To Give <br/>Protection To You <br/>And Your Family
            </p>
            <p className='sm:text-[1vw] text-[2.5vw] font-Inknut '>
              Building a secure future requires constant vigilance and <br/> innovation. We must take ahead of threats to protect<br/> ourselves and those we love.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              x: { type: "spring", stiffness: 50 },
                   duration: 1}}
            className='w-[0%] sm:w-[40%] '>
            <img src={worldMap} loading='lazy' className='' alt="world map" />
          </motion.div>
        </section>
      </main>
    </div>
  );
}

export default Hero;
