import React from "react";
import Phone from "./Images/Phone2.svg";
import Linkedin from "./Images/Linkedin.svg";
import linkedin from "./Images/linkedinicon.svg";

export default function Frame1() {
  return (
    <section className="flex justify-between sm:flex-row flex-col  sm:mx-[5%] mx-[8%]">
      <div className="sm:w-[40vw] w-[100%] flex flex-col justify-between  sm:gap-0 gap-10">
        <p className=" font-Inknut sm:text-[3vw] text-[6vw]  ">Follow Us on</p>
        <img src={Linkedin} className="sm:w-[100%] w-[80%] " loading="lazy" alt="Phone" />
        <div className="w-[100%]">
          <p className=" sm:text-[1vw] text-[3vw] text-justify  font-Inknut ">
            Sicu-aura is committed to providing its customers with the highest
            quality technologies and services. We encourage you to follow us on
            LinkedIn and join our community of our innovative journey. We look
            forward to connecting with you!
          </p>
        </div>
        <div className="flex justify-center">
        <img src={Phone} loading="lazy" className="sm:w-[0%] sm:invisible w-[80%]" alt="Phone" />
      </div>
        <button
          onClick={() =>
            window.open("https://www.linkedin.com/company/sicu-aura/")
          }
          className=" bg-white flex gap-[5%] justify-center items-center border rounded-full text-black font-Inknut font-semibold sm:text-[1vw] text-[3vw] sm:w-[20vw] p-[3%]"
        >
            <img src={linkedin} className="sm:w-[15%] w-[10%]" alt="linkdin"></img>
          Follow us
        </button>
      </div>
      <div className="">
        <img src={Phone} loading="lazy" className="sm:w-[40vw] w-[0vw]" alt="Phone" />
      </div>
    </section>
  );
}
