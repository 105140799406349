import React, { useRef, useState } from "react";
import {
  List,
  ListItem,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Card,

} from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import facebook from "./Images/facebookicon.svg";
import instagram from "./Images/instagramicon.svg";
import linkedin from "./Images/linkedinicon.svg";
import google from './Images/google-logo.png'
import { IoIosArrowForward } from "react-icons/io";
import whatsapp from "./Images/whatsappicon.svg";
import x from './Images/Twitter.svg'
import { Link } from "react-router-dom";
import { twJoin, twMerge } from "tailwind-merge";
import emailjs from '@emailjs/browser';
const Footer = () => {
  const [email, setEmail] = useState("");
  const form = useRef();
  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
     console.log(form)
    emailjs
      .sendForm('service_jubv84e', 'template_8vvj0re', form.current, {
        publicKey: 'RkQ6gT9u8lwH4fBmo',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <footer className=" bg-white w-screen h-fit flex-col flex text-black font-Kadwa mt-20">
      <section className=" w-[90%] mx-[4%] sm:w-[100%] sm:mx-0 flex-row md:flex md:flex-wrap md:justify-around grid grid-cols-1 sm:grid-cols-2 mt-2 px-5  py-6 gap-6">
       
        <div className=" w-auto gap-6 flex flex-col">
          <div className="font-semibold md:first-letter:font-bold md:font-semibold md:block md:text-xl ">
            | KNOW US
          </div>
          <List
            className=" text-sm md:text-base "
            spacing={3}
            marginBottom={10}
            marginLeft={-2}
            marginTop={-4}
          >
            <ListItem>
              <Link
                to="/"
                className="flex flex-row items-center  border-b-2 p-2 text-gray-500"
              >
                <IoIosArrowForward />
                Home
              </Link>
            </ListItem>
            <ListItem>
              <Link
                to="/About"
                className="flex flex-row items-center  border-b-2 p-2 text-gray-500"
              >
                <IoIosArrowForward />
                About US
              </Link>
            </ListItem>
            <ListItem>
              <Link
                to="/Blog"
                className="flex flex-row items-center  border-b-2 p-2 text-gray-500"
              >
                <IoIosArrowForward />
                Blog
              </Link>
            </ListItem>
          </List>
          <button
            onClick={() =>
              window.open("https://g.page/r/CYSIQkseQ0BiEBM/review")
            }
            className=" md:text-black md:first-letter:font-bold font-semibold md:text-xl flex items-center mb-[15%] sm:mb-0"
          >
            | RATE US ON{" "}
            <img src={google} alt="google icon" className="ps-1 sm:w-8" />
          </button>
     
        </div>
        <div className="w-auto">
          <div className=" font-semibold md:first-letter:font-bold md:font-semibold md:block md:text-xl md:w-fit ">
            | USEFUL LINKS
          </div>
          <List
            className=" text-sm md:text-base "
            spacing={3}
            margin={2}
            marginLeft={-2}
          >
            <ListItem>
              <Link
                to="/Career"
                className="flex flex-row items-center  border-b-2 p-2 text-gray-500"
              >
                <IoIosArrowForward />
                Career
              </Link>
            </ListItem>
            <ListItem>
              <Link
                to="/Directors"
                className="flex flex-row items-center  border-b-2 p-2 text-gray-500"
              >
                <IoIosArrowForward />
                Directors
              </Link>
            </ListItem>
            <ListItem>
              <Link
                to="/Statistics"
                className="flex flex-row items-center  border-b-2 p-2 text-gray-500"
              >
                <IoIosArrowForward />
                Statistics
              </Link>
            </ListItem>
            <ListItem>
              <Link
                to="/"
                className="flex flex-row items-center  border-b-2 p-2 text-gray-500"
              >
                <IoIosArrowForward />
                Privacy Policy
              </Link>
            </ListItem>
            <ListItem className="flex flex-row items-center whitespace-nowrap  border-b-2 p-2 text-gray-500">
              <IoIosArrowForward />
              Terms And Condition
            </ListItem>
          </List>
        </div>
        <div className="w-auto">
          <div className="font-semibold md:first-letter:font-bold md:font-semibold md:block md:text-xl md:mb-5">
            | CONTACT US
          </div>
          <List spacing={4} margin={2}>
            <ListItem className="flex flex-col items-start Inknut text-black gap-3 mb-6">
              <h3 className=" font-semibold text-base md:font-semibold md:text-base ">
                Address:
              </h3>
              <p className=" font-mono text-sm font-medium text-gray-500">{`Kolkata, West Bengal`}</p>
            </ListItem>
            <ListItem className="flex flex-col items-start Inknut text-black gap-3 mb-6">
              <h3 className=" font-semibold text-base ">Email:</h3>
              <p className=" font-mono text-sm font-medium text-gray-500">
                support@sicu-aura.com
              </p>
            </ListItem>
            <ListItem className="flex flex-col items-start Inknut text-black gap-3">
              <h3 className=" font-semibold text-base ">Contact Number:</h3>
              <p className=" font-mono text-sm w-max font-medium text-gray-500">
              +91-6289179476<span className="collapse">+91-6289179476</span>
              </p>
            </ListItem>
          </List>
        </div>
        <div className="md:w-[30%] lg:w-[20%] ">
          <div className="font-semibold md:first-letter:font-bold md:font-semibold md:block md:text-xl ">
            | SUBSCRIPTION
          </div>
          <p className="text-gray-500 text-[80%] tracking-wide leading-5  my-[5%] md:font-Inknut md:text-gray-500 md:font-light md:text-xs md:w-72 md:my-4 md:tracking-wider md:leading-5 ">
            With Our Skills Put Together, You Get An Ensemble Capable Of Doing
            Anything And Everything The Brand Needs. Follow Us To Get Our Latest
            Updates.
          </p>
        <form 
         action="https://formspree.io/f/xdoqdpbj"
         method="POST"
        // action="https://formspree.io/f/mgegzpra"
        // method="POST"
        >
          <InputGroup  className="text-xs  md:w-full"
         
          >
            <Input
              placeholder="Enter your Email"
              value={email}
              onChange={handleInputChange}
              type="email"
              name="email"
              required
              id="email"
             
            />
            <input type="text" className=" invisible w-0 h-0" name="message" value={'New Subscriber from Sicu-aura'}></input>
            <InputRightElement className="w-fit">
              <div>

              <Button
              bgColor={"black"}
              color={"white"}
              fontSize={"xx-small"}
              type="submit"
                className={"w-auto text-[8px] md:w-full md:h-full md:flex flex-row md:items-center md:justify-end bg-[#0F182B] text-white md:hover:bg-gray-200 hover:text-black transition-all  md:text-md"}
              >
                Subscribe
              </Button>
              </div>
            </InputRightElement>
          </InputGroup>

        </form>

     

          <div className=" font-semibold mt-[5%] md:first-letter:font-bold md:font-semibold md:block md:text-xl md:mt-6 ">
            | FOLLOW US ON
          </div>
          <div className="flex flex-row items-center gap-4 mb-6 mt-2 text-4xl justify-start">
            <img
              className=" w-[12%] md:hover:cursor-pointer"
              onClick={() =>
                window.open("https://www.linkedin.com/company/sicu-aura/")
              }
              src={linkedin}
            />
            <img
              className=" w-[12%] md:hover:cursor-pointer"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/profile.php?id=100092369332099&mibextid=LQQJ4d"
                )
              }
              src={facebook}
            />
            {/* <img
              className=" w-[17%] md:hover:cursor-pointer"
              onClick={() => window.open("https://twitter.com/SicuAura")}
            /> */}
                 <img
              className="w-[14%] md:hover:cursor-pointer"
              onClick={() =>
                window.open(
                  "https://wa.me/message/TPL7LZQA3Z3MM1"
                )
              }
              src={whatsapp}
            />
              <img
             className="w-[12%] md:hover:cursor-pointer"
             onClick={() => window.open("https://twitter.com/SicuAura")}
             src={x}
           />
            <img
              className="w-[12%] md:hover:cursor-pointer"
              onClick={() => window.open("https://instagram.com/sicu.aura")}
              src={instagram}
            />
               
            {/* <FaLinkedin className=' hover:cursor-pointer' onClick={()=>window.open('https://www.linkedin.com/company/sicu-aura/')} />
                <FaSquareFacebook className=' hover:cursor-pointer' onClick={()=>window.open('https://www.facebook.com/profile.php?id=100092369332099&mibextid=LQQJ4d')} />
                <FaTwitter className=' hover:cursor-pointer' onClick={()=>window.open('https://twitter.com/SicuAura')} /> */}
                {/* <img
              className=" w-[12%] md:hover:cursor-pointer"
              // onClick={() => window.open("https://instagram.com/sicu.aura")}
              src={x}
            /> */}
          </div>
        </div>
      </section>
     <div className="flex sm:flex-row flex-col justify-between">
     <p className="px-[1%] sm:text-base text-xs text-center invisible sm:w-auto w-0 h-0">{` Sicu-aura Innoviz`}</p>
      <p className="px-[1%] sm:text-base text-xs text-center">{`*Terms and Condition Applied* | Copyright © 2024 Sicu-Aura. All Rights Reserved.`}</p>
      <p className="px-[1%] sm:text-base text-xs text-center cursor-pointer" onClick={() => window.open("https://sicu-aura-innoviz.com/")}>{`Designed & Developed by Sicu-aura Innoviz`}</p>

     </div>
    </footer>
  );
};

export default Footer;
